// method for parallel async module loading
import parallelDynamicImport from '@/client/utilities/parallelDynamicImport.js';

// TODO: this is global dependency now and can not be overriden. fix this
import asyncOperations from '@/client/extensions/composition/asyncOperations.js';

let appName   = process.env.VUE_APP_APPLICATION_NAME;

let {getApplicationParts, getApplicationPartsSSR} = require('@/client/applications/'+appName+'/'+appName+'.js');

let isSSR =  (typeof window !== 'object');



let createApplication = async (context) => {
    // import all the stuff we need (application exports, and createApp, using a single await for maximum performance
    let importDefinition    = [
        {importKey : 'createApp', targetKey: 'createApp',promise : import('vue')},
        {importKey : 'createSSRApp', targetKey: 'createSSRApp', promise : import('vue')}
    ];

    let imports             = await Promise.allSettled([getApplicationParts(),parallelDynamicImport(importDefinition)]);
    let parts               = imports[0].value;
    let createApp;

    if (isSSR) {
        createApp           = imports[1].value.createSSRApp;
    } else {
        createApp           = imports[1].value.createApp;// todo" check - someone said we need to use also here createSSRApp
    }

    // create the application
    let application         = createApp(parts.app);

    if (parts.store) {
        // provide this store to async operations. Async ops now has access to store
        asyncOperations({}, false, {mainStoreInstance:parts.store})
        application.use(parts.store);
        application.store = parts.store;

        // fetch config if needed
        // do not check config via store, becuase the module may not exist in app. if the useServerConfig is on,
        // assume app developer took care of it and left us the correct store API
        if (config.useServerConfig && config.waitForServerConfig) {
            await parts.store.dispatch('config/updateFromServer', {allowLoadFromWindow: true});
        }

        if (config.useServerConfig && ! config.waitForServerConfig) {
            parts.store.dispatch('config/updateFromServer');
        }
    }

    // create router, if the key is a method
    if(parts.router && typeof parts.router === 'function') {
        parts.router = parts.router(application);
    }

    if (parts.router) {
        application.use(parts.router);
    }

    if (parts.router && parts.store) {
        parts.router.$store     = parts.store;
        parts.router.isStoreSet = true;
        parts.store.$router     = parts.router;
        parts.store.isRouterSet = true;
    }


    // apply all the extensions it asked for
    for (const [index, extension] of Object.entries(parts.extensions || {})) {
        application.use(extension);
    }



    let result = {app:application, router: parts.router || false, store: parts.store || false};

    if (context && context.url && parts.router) {
        return await new Promise ((fulfil) => {
            parts.router.push(context.url);
            parts.router.isReady().then(() => {
                fulfil(result);
            });
        });
    } else {
        return result;
    }
};

let mountAppClientSide =  (app) => {
    app.mount('#app', true);
};

if ( ! isSSR) {
    // automatically mount app if this is on the client side
    createApplication().then(({app}) => mountAppClientSide(app));
}

export {
    createApplication, getApplicationParts, getApplicationPartsSSR
};




