import parallelDynamicImport from '@/client/utilities/parallelDynamicImport.js';
const appName   = process.env.VUE_APP_APPLICATION_NAME;


/**
 * Definition of imports
 * @type {*[]}
 */
let importDefinition = [

    {
        targetKey   : 'styles',
        promise     : import(/* webpackChunkName: "app-vendors" */ '@/client/assets/index.js')
    },

    {
        targetKey   : 'importPlugins',
        promise     : import('@/client/extensions/plugins/index.js')
    },
    {
        targetKey   : 'importDirectives',
        promise     : import('@/client/extensions/directives/index.js')
    },
    {
        targetKey   : 'routerFactory',
        promise     : import('@/client/router')
    },
    {
        targetKey   : 'createStore',
        promise     : import( '@/client/store')
    },
    {
        targetKey   : 'app',
        promise     : import('@/client/applications/'+appName+'/components/App.vue')
    },
    {
        targetKey   : 'sgirotUserPlugin',
        promise     : import('@/client/applications/'+appName+'/extensions/mixins/sgirotUser.js')
    },
];

let getApplicationParts = async () => {
    let imports    = await parallelDynamicImport(importDefinition);
//console.log(await imports.importPlugins());
    return {
        app: imports.app,
        store: imports.createStore(),
        router : imports.routerFactory, // exporting router as a factory funciton is supported
        extensions : {
            plugins : await imports.importPlugins(),
            directives: imports.importDirectives(),
            sgirotUserPlugin : imports.sgirotUserPlugin
        },
    }
};

let getApplication = async () => {
    return await getApplicationParts().app;
};
//init();

export {
    getApplicationParts, getApplication
}
