import {computed, nextTick, watchEffect} from "vue";

export default {
    install (app) {
        app.mixin({
                      computed : {
                          isSuperAdmin() {
                              let profile = this.$store.getters['user/profile'];
                              return profile && profile.isSuperAdmin;
                          },
                          isExpert() {
                              let profile = this.$store.getters['user/profile'];
                              return profile && profile.isExpert;
                          },
                          isSource() {
                              let profile = this.$store.getters['user/profile'];
                              return profile && profile.isSource;
                          },
                      }
            
                  })
    },
    
}
