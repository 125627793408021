let debug;
let useSSR;
// if debug specifically on in env - debug on
if (process.env.VUE_APP_DEBUG && process.env.VUE_APP_DEBUG !== 'false' && process.env.VUE_APP_DEBUG !== '0') {
    debug = true;
} else {
    debug = false;
}

if (process.env.VUE_APP_USE_SSR && process.env.VUE_APP_USE_SSR !== 'false' && process.env.VUE_APP_USE_SSR !== '0') {
    useSSR = true;
} else {
    useSSR = false;
}

// if env reflects in an obvious manner that this is NOT production - debug is on
if (['dev','development','test','testing','stage','staging'].includes(process.env.NODE_ENV)) {
    debug = true;
}

let isStage      = ['stage', 'staging'].includes(process.env.NODE_ENV);
let isProduction = ['production'].includes(process.env.NODE_ENV);

export default {
    debug,
    // should service worker be used
    useServiceWorker : false,
    // should saffron plugin use $s namespace
    useNamespaceS : true, // this is not error!!! use namespace S - capital S.
    // does this app expect SSR? notice server side config might also be required
    useSSR : useSSR,
    //
    preferredRouterHistory : (isProduction || isStage) ? 'web' : 'webHash',
    // server config override?
    useServerConfig : true,
    // should app wait for server config, or render as is and load it async
    waitForServerConfig: true,
    // should app wait for server language, or get it asynchronously
    waitForServerLanguage: true,
    // theme color for browsers - can not use css variables
    themeColor:'#4e469c',
}
