export default  {
    fullName         : {
        name      : 'fullName',
        type      : 'text',
        label     : 'sgirot.expert.fields.fullName',
        placeholder: 'sgirot.user.fullNameFieldPlaceHolder',
        class     : '',
        icon      : 'user',
        id        : '',
        validation: {
            required: {}
        },
        group     : 'group1',
    },
    email            : {
        name      : 'email',
        type      : 'text',
        label     : 'sgirot.expert.fields.email',
        placeholder: 'sgirot.user.emailFieldPlaceHolder',
        icon      : 'mail',
        class     : '',
        id        : '',
        validation: {
            email   : {},
        },
        group     : 'group1',
    },
    password         : {
        name      : 'password',
        type      : 'password',
        label     : 'sgirot.expert.fields.password',
        placeholder: 'sgirot.user.passwordFieldPlaceHolder',
        icon      : 'lock',
        class     : '',
        id        : '',
        //value: null, // prevent autocomplete for non supporting browser
        autocomplete : 'off',
        validation: {
            minLength: {
                params: {min: 6}
            }
        },
        group     : 'group1',
    },
}
