// import causes issues for some reason. and for this, we may lose vue tree-shakability. This is a framework so we are ok with importing all of Vue
let vue = require('vue');

/**
 * Custom async import, that will look for overrides, loading the override if possible
 * @param route
 * @returns {Promise<*>}
 */
const customImport = async function (route) {
    let cleanRoute = route;
    let overrideKey = `@/overrides/client/${cleanRoute}`;
    if (route.indexOf('@/') === 0 || route.indexOf('./') === 0) {
        cleanRoute = route.substring(2);
    }

    try {
        return await import(/* webpackChunkName: "[request]" */ `${overrideKey}`);
    } catch (e) {
        console.log(`@/client/${cleanRoute}`);
        return await import(/* webpackChunkName: "[request]" */ `@/client/${cleanRoute}`);
    }
};

const customImportWithAppSource = async function (route) {
    let appName   = process.env.VUE_APP_APPLICATION_NAME;
    let cleanRoute = route;

    if (route.indexOf('@/') === 0 || route.indexOf('./') === 0) {
        cleanRoute = route.substring(2);
    }

    let cleanAppRoute = `applications/${appName}/${cleanRoute}`;

    let overrideKey = `@/overrides/client/${cleanRoute}`;
    let appKey = `@/client/applications/${appName}/${cleanRoute}`;
    let appOverrideKey = `@/overrides/client/applications/${appName}/${cleanRoute}`;
    let defaultKey = `@/client/${cleanRoute}`;


    try {
        return await import(/* webpackChunkName: "[request]" */ `${appOverrideKey}`);
    } catch (e) {

    }

    try {
        return await import(/* webpackChunkName: "[request]" */ `@/client/${cleanAppRoute}`);
    } catch (e) {

    }

    try {// todo: this is not tested
        return await import(/* webpackChunkName: "[request]" */ `${overrideKey}`);
    } catch (e) {

    }

    // import core file - if this fails, than we allow the exception to be thrown
    return await import(/* webpackChunkName: "[request]" */ `@/client/${cleanRoute}`);

};

function ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function titleCase (string) {
    'use strict';
    var smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i;
    var alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/;
    var wordSeparators = /([ :–—-])/;

    return string.split(wordSeparators)
        .map(function (current, index, array) {
            if (
                /* Check for small words */
                current.search(smallWords) > -1 &&
                /* Skip first and last word */
                index !== 0 &&
                index !== array.length - 1 &&
                /* Ignore title end and subtitle start */
                array[index - 3] !== ':' &&
                array[index + 1] !== ':' &&
                /* Ignore small words that start a hyphenated phrase */
                (array[index + 1] !== '-' ||
                    (array[index - 1] === '-' && array[index + 1] === '-'))
            ) {
                return current.toLowerCase()
            }

            /* Ignore intentional capitalization */
            if (current.substr(1).search(/[A-Z]|\../) > -1) {
                return current
            }

            /* Ignore URLs */
            if (array[index + 1] === ':' && array[index + 2] !== '') {
                return current
            }

            /* Capitalize the first letter */
            return current.replace(alphanumericPattern, function (match) {
                return match.toUpperCase()
            })
        })
        .join('')
}

// todo: maybe call the utilities module in root, this has 2 copies now
let debugFunction = (message, level, data) => {
    if ( ! config.debug) {
        return;
    }

    level = parseInt(level);

    if (typeof console === 'undefined') {
        return;
    }

    if (typeof data === 'undefined') {
        data = '[data was not provided by caller]';
    }

    let levelColor = 'white';
    let wrapperColor = "#f50a41";

    switch (level) {
        case 3:
        case '3':
            levelColor = wrapperColor;
            break;
        case 2:
        case '2':
            levelColor = '#d97409';
            break;
        case 1:
        case '1':
        default:
            levelColor = 'white';
            break;
    }



    console.log('%c/============================================================', "color: "+wrapperColor+";");
    console.log('%c|%c Error [%cLevel '+level+'%c]:', "color: "+wrapperColor+";", "color:white;", "color: "+levelColor+";", "color:white;");
    console.log('%c|%c Description: %c' + message, "color: "+wrapperColor+";", "color:white;", "color:white;;");
    console.log('%c| %cdata: ',"color: "+wrapperColor+";", "color:white; ", data );
    console.log('%c\\============================================================', "color: "+wrapperColor+";");

    if (level > 2) {
        console.log('Throwing Exception for error with level 3 and above');
        throw new Error(message);
    }

};

function requireAsset(path) {
    let result  = false, context;
    let appName = process.env.VUE_APP_APPLICATION_NAME;

    // check for application asset override
    // context = require.context('@/', true, /overrides\/client\/applications\/\.\/.*\/assets\/static.*/);
    //
  //  context = require.context('@/overrides/client/applications/', true, /^\.\/.*\/assets\/static.*$/);
    context = require.context('@/', true, /overrides\/client\/applications\/.*\/assets\/static\/.*/);

    context.keys().forEach(key => {
        let target = `./${appName}/assets/static/` + path;
        let matchKey = key.replace('overrides/client/applications/', '');

        // filter only the modules for out application
        if ( target === matchKey) {
            result = context(key);
            return true;
        }
    });

    if (result) {
        return result;
    }


    // check for application asset
    context = require.context('@/client/applications/', true, /^\.\/.*\/assets\/static.*$/);
    context.keys().forEach(key => {
        let target = `./${appName}/assets/static/` + path;

        // filter only the modules for out application
        if ( target === key) {
            result = context(key);
            return true;
        }
    });

    if (result) {
        return result;
    }

    // check for core override;
  //  context = require.context('@/overrides/client/assets/static/', true, /\.*/);
     context = require.context('@/', true, /\/overrides\/client\/assets\/static\.*/);


    context.keys().forEach(key => {
        let compare = key.replace('./overrides/client/assets/static/', '');

        if (compare === path) {
            result = context(key);
            return true;
        }
    });

    if (result) {
        return result;
    }

    // resort to core file
    try {
        return require(`@/client/assets/static/${path}`);
    } catch (e) {
        debug('Can not find asset, probably missing file. check your path',2 ,{exception:e,message:e.message,path:path});
        return null;
    }

}

let unique = 0;

let getUniqueNumber = () => {
    unique = unique + 1;

    return unique;
};

let isSSR = () => {
    return typeof window !== 'object';
};

let wait = async function (time) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};

// TODO: consider using reactivity
/**
 * Return a promise that fulfills when a condition is met. Doesnt use reactivity
 * @param handler - function whose return value ( == bool) indicated if the condition is met
 * @param pollRate - interval between checking the condition (ms)
 * @returns {Promise<*>}
 */

let waitUntil = async function (handler, pollRate = 50) {
    let interval;

    return new Promise((fulfil, reject, immediat = true) => {

        let checkCondition = () => {
            return handler();
        };

        // check immediatly if needed
        if (immediat && checkCondition()) {

            clearInterval(interval);
            fulfil();
            return;
        }

        // start polling
        interval = setInterval(() => {
            if (checkCondition()) {
                clearInterval(interval);
                fulfil(true);
            }
        }, pollRate);
    });
};

module.exports = {

    customImport,
    customImportWithAppSource,
    waitUntil,
    ucFirst,
    titleCase,
    requireAsset,
    getUniqueNumber,
    isSSR,
    wait,
    debug: debugFunction,
};
